<template>
  <div class="main-contain">
    <div class="check-group">
      <el-radio-group v-model="radio" @change="changebt">
        <el-radio :label="0">全部</el-radio>
        <el-radio :label="1">仅老师参与</el-radio>
        <el-radio :label="2">回复最多</el-radio>
      </el-radio-group>
    </div>
    <div class="item" v-for="(item, index) in qadata" :key="index">
      <div class="title" @click="toreply(item)">{{ item.title }}</div>
      <div class="item-two">
        <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">
          置顶
        </div>
        <div v-if="item.is_teacher_join === 1" class="item-two-bt">
          老师参与
        </div>
      </div>
      <div class="text">
        {{ item.content }}
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar" />
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at }}</div>
          <!-- <div class="time">11:25</div> -->
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <el-image class="elimage1" :src="icon1" />
            <div class="num">{{ item.view_count }}</div>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="setlikebt(item.id)">
            <el-image class="elimage2" :src="icon2" />
            <div class="num">{{ item.like_count }}</div>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="toreply(item)">
            <el-image class="elimage3" :src="icon3" />
            <div class="num">{{ item.reply_count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getQaList, setQaLike } from "@/api/qa";
import dayjs from "dayjs";
const cityOptions = ["仅老师参与", "回复最多"];
export default {
  name: "virtualexperiment",
  components: {},
  data() {
    return {
      radio: 0,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      qadata: [],
    };
  },
  inject: ["vr_id"],
  created() {
    //keyword:关键字  belong_type:归属类型，0：全部，1：我发表的问答
    //type:类型，0：全部，1：老师参与，2：按回复数量排序（从多到少）
    //related_type:关联类型，关联类型，0：无关联，1：关联课程，2：关联虚拟仿真
    //related_id:关联ID
    this.getqalist({
      keyword: "",
      belong_type: 0,
      type: 0,
      related_type: 2,
      related_id: this.vr_id,
    });
  },
  methods: {
    initdata(data) {
      console.log(data)
    },
    getqalist(params) {
      this.qadata = [];
      getQaList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              this.qadata.push({
                author_name: item.author_name,
                avatar: item.avatar,
                content: item.content,
                created_at: dayjs
                  .unix(item.created_at)
                  .format("YYYY-MM-DD h:mm:ss"),
                id: item.id,
                is_teacher_join: item.is_teacher_join,
                is_top: item.is_top,
                like_count: item.like_count,
                related_name: item.related_name,
                related_type: item.related_type,
                reply_count: item.reply_count,
                title: item.title,
                view_count: item.view_count,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toreply(item) {
      this.$router.push({
        path: "/home/virtualsimulationexperiment/interactiveanswerreply",
        query: {
          id: this.vr_id,
          qa_id: item.id,
        },
      });
    },
    changebt(value) {
      this.getqalist({
        keyword: "",
        belong_type: 0,
        type: parseInt(value),
        related_type: 2,
        related_id: this.vr_id,
      });
    },
    setlikebt(id) {
      this.setQaLike({ id: id });
    },
    setQaLike(params) {
      setQaLike(params)
        .then((response) => {
          this.getqalist({
            keyword: "",
            belong_type: 0,
            type: 0,
            related_type: 2,
            related_id: this.vr_id,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  // border: 1px solid red;

  padding: 10px;
  background: #ffffff;
  .check-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-left: 80px;
    .check-all {
      margin-right: 20px;
    }
  }
  .item {
    padding-top: 20px;
    margin-top: 10px;
    min-height: 180px;
    border-bottom: 1px solid #ececec;
    .title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #222222;
      cursor: pointer;
    }
    .item-two {
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .item-two-bt {
        margin-right: 10px;
        padding: 2px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .item-two-bt-active {
        border: 1px solid #ca261d;
      }
    }
    .text {
      margin-top: 10px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .bottom-contain {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .time {
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #a0a0a0;
        }
      }
      .bottom-right {
        padding-right: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          .elimage1 {
            width: 22px;
            height: 16px;
          }
          .elimage2 {
            width: 19px;
            height: 21px;
            margin-bottom: 4px;
          }
          .elimage3 {
            width: 19px;
            height: 16px;
          }
          .num {
            margin-left: 6px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #a0a0a0;
          }
        }
        .line {
          margin-left: 20px;
          margin-right: 20px;
          height: 25px;
          border-right: 2px solid #9b9b9b;
        }
      }
    }
  }
}
</style>
