<template>
  <!-- 项目团队 -->
  <div class="main-contain">
    <div class="itemcontain" v-for="(item, index) in datalist" :key="index">
      <div class="itemup">
        <el-image class="elimage" :src="item.photo" />
        <!-- <el-image class="elimage" :src="randomimage()" /> -->
        <div class="itemupright">
          <div class="name">
            <div class="left">{{ item.name }}</div>
            <div class="right" v-if="item.is_leader === 1">负责人</div>
          </div>
          <div class="intro">{{ item.job_name }}</div>
        </div>
      </div>
      <div class="itembottom" v-html="item.intro"></div>
    </div>
  </div>
</template>
<script>
import { getvrteams } from "@/api/vr";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      datalist: [],

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
    };
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    initdata(data) {
      this.getvrteams({
        id: data.id,
      });
    },

    //获取虚拟仿真项目团队列表
    getvrteams(params) {
      getvrteams(params)
        .then((response) => {
          if (response.code == 0 && response.data) {
            this.datalist = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  .itemcontain {
    .itemup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .elimage {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .itemupright {
        .name {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .left {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }
          .right {
            margin-left: 10px;
            width: 52px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: rgba(183, 235, 143, 1);
            font-weight: 400;
            font-size: 12px;
            color: #52c41a;
          }
        }
        .intro {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .itembottom {
      margin-top: 10px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
      color: #777;
    }
  }
}
</style>
